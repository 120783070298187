var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "content-header" },
        [
          _c(
            "b-col",
            {
              staticClass: "content-header-left mb-2",
              attrs: { cols: "12", md: "9" }
            },
            [
              _c(
                "b-row",
                { staticClass: "breadcrumbs-top" },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h2",
                      {
                        staticClass: "content-header-title float-left pr-1 mb-0"
                      },
                      [_vm._v(" Reports ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "breadcrumb-wrapper" },
                      [
                        _c(
                          "b-breadcrumb",
                          [
                            _c(
                              "b-breadcrumb-item",
                              { attrs: { to: "/apps/reports/type" } },
                              [
                                _c("feather-icon", {
                                  staticClass: "align-text-top",
                                  attrs: { icon: "HomeIcon", size: "16" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-breadcrumb-item",
                              { on: { click: _vm.goBack } },
                              [_vm._v(" Reports ")]
                            ),
                            _c("b-breadcrumb-item", { attrs: { active: "" } }, [
                              _vm._v(" Alarm Response Report ")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.reportData &&
      _vm.clientOptions &&
      (_vm.$can("read", _vm.abilityRequired) ||
        _vm.$can("create", _vm.abilityRequired) ||
        _vm.$can("update", _vm.abilityRequired))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Report Details ")])]),
              _c(
                "b-card-body",
                [
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var handleSubmit = ref.handleSubmit
                            return [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return handleSubmit(_vm.validateForm)
                                    }
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.report_type,
                                        expression: "reportData.report_type"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "report_type"
                                    },
                                    domProps: {
                                      value: _vm.reportData.report_type
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "report_type",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.time_zone,
                                        expression: "reportData.time_zone"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "time_zone"
                                    },
                                    domProps: {
                                      value: _vm.reportData.time_zone
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "time_zone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.reportData.client_display_name,
                                        expression:
                                          "reportData.client_display_name"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "client_display_name"
                                    },
                                    domProps: {
                                      value: _vm.reportData.client_display_name
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "client_display_name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.attachment_list,
                                        expression: "reportData.attachment_list"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "attachment_list"
                                    },
                                    domProps: {
                                      value: _vm.reportData.attachment_list
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "attachment_list",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm.reportData.report_id
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Report ID",
                                                    "label-for": "report_id"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.reportData.report_id
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Report Date",
                                                    "label-for":
                                                      "report_date_local"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.reportData
                                                          .report_date_local
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.reportData.report_id
                                    ? _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Submitted By",
                                                    "label-for":
                                                      "user_display_name"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.reportData
                                                          .user_display_name
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Client",
                                                "label-for": "clientname"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "clientname",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.clientOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "clientname"
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleSelectClient
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .clientname,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "clientname",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.clientname"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Site",
                                                "label-for": "sitename"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "sitename",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.siteOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "sitename"
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleSelectSite
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .sitename,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "sitename",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.sitename"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Site Address",
                                                "label-for": "site_address"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "site_address",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "site_address"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .site_address,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "site_address",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.site_address"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Date and Time Received",
                                                "label-for": "received_date"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "received_date",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("flat-pickr", {
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              id:
                                                                "received_date",
                                                              config: {
                                                                enableTime: true,
                                                                dateFormat:
                                                                  "Y-m-d H:i:ss"
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .received_date,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "received_date",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.received_date"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Arrival",
                                                "label-for": "arrival_time"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "arrival_time",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-timepicker",
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                id:
                                                                  "arrival_time",
                                                                locale: "en"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.reportData
                                                                    .arrival_time,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.reportData,
                                                                    "arrival_time",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "reportData.arrival_time"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Departure",
                                                "label-for": "departure_time"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "departure_time",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-timepicker",
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                id:
                                                                  "departure_time",
                                                                locale: "en"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.reportData
                                                                    .departure_time,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.reportData,
                                                                    "departure_time",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "reportData.departure_time"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "",
                                                "label-for": "is_keys_used"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "is_keys_used" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                              attrs: {
                                                                id:
                                                                  "is_keys_used",
                                                                checked: "true",
                                                                "unchecked-value":
                                                                  "false",
                                                                name:
                                                                  "check-button",
                                                                switch: "",
                                                                inline: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.reportData
                                                                    .is_keys_used,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.reportData,
                                                                    "is_keys_used",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "reportData.is_keys_used"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Keys Used "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.reportData.is_keys_used
                                        ? _c(
                                            "b-col",
                                            { attrs: { md: "4" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Key# Box#",
                                                    "label-for": "keyNumBoxNum"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "keyNumBoxNum"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "keyNumBoxNum",
                                                                    placeholder:
                                                                      "Key#, Box#"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .keyNumBoxNum,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.reportData,
                                                                        "keyNumBoxNum",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.keyNumBoxNum"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Alarm Type",
                                                "label-for": "alarm_type_list"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "alarm_type_list"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "hold_up",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .alarm_type_list
                                                                        .hold_up,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .alarm_type_list,
                                                                        "hold_up",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.alarm_type_list.hold_up"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Hold Up "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "panic_duress",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .alarm_type_list
                                                                        .panic_duress,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .alarm_type_list,
                                                                        "panic_duress",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.alarm_type_list.panic_duress"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Panic/Duress "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "improper_open_close",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .alarm_type_list
                                                                        .improper_open_close,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .alarm_type_list,
                                                                        "improper_open_close",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.alarm_type_list.improper_open_close"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Improper Open/Close "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "day_night_early",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .alarm_type_list
                                                                        .day_night_early,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .alarm_type_list,
                                                                        "day_night_early",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.alarm_type_list.day_night_early"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Day / Night Early "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "day_night_late",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .alarm_type_list
                                                                        .day_night_late,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .alarm_type_list,
                                                                        "day_night_late",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.alarm_type_list.day_night_late"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Day / Night Late "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "safe_vault",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .alarm_type_list
                                                                        .safe_vault,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .alarm_type_list,
                                                                        "safe_vault",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.alarm_type_list.safe_vault"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Safe Vault "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "perimeter",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .alarm_type_list
                                                                        .perimeter,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .alarm_type_list,
                                                                        "perimeter",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.alarm_type_list.perimeter"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Perimeter "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "interior",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .alarm_type_list
                                                                        .interior,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .alarm_type_list,
                                                                        "interior",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.alarm_type_list.interior"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Interior "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "critical_function",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .alarm_type_list
                                                                        .critical_function,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .alarm_type_list,
                                                                        "critical_function",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.alarm_type_list.critical_function"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Critical Function "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id: "fire",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .alarm_type_list
                                                                        .fire,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .alarm_type_list,
                                                                        "fire",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.alarm_type_list.fire"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Fire "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "fire_trouble",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .alarm_type_list
                                                                        .fire_trouble,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .alarm_type_list,
                                                                        "fire_trouble",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.alarm_type_list.fire_trouble"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Fire Trouble "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "glass_break",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .alarm_type_list
                                                                        .glass_break,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .alarm_type_list,
                                                                        "glass_break",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.alarm_type_list.glass_break"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Glass Break "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "alarm_other",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .alarm_type_list
                                                                        .other,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .alarm_type_list,
                                                                        "other",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.alarm_type_list.other"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Other "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Alarm Type Other",
                                                "label-for":
                                                  "alarm_type_list_other"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "alarm_type_list_other"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "alarm_type_list_other"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .alarm_type_list_other,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "alarm_type_list_other",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.alarm_type_list_other"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2 mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Dispatch Request Type",
                                                "label-for":
                                                  "dispatch_request_type_list"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name:
                                                    "dispatch_request_type_list"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "crisis",
                                                                    name:
                                                                      "dispatch_request_type_list",
                                                                    checked:
                                                                      "true",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .dispatch_request_type_list
                                                                        .crisis,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .dispatch_request_type_list,
                                                                        "crisis",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.dispatch_request_type_list.crisis"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Crisis "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "elevator_entrapment",
                                                                    name:
                                                                      "dispatch_request_type_list",
                                                                    checked:
                                                                      "true",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .dispatch_request_type_list
                                                                        .elevator_entrapment,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .dispatch_request_type_list,
                                                                        "elevator_entrapment",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.dispatch_request_type_list.elevator_entrapment"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Elevator Entrapment "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "general_assist",
                                                                    name:
                                                                      "dispatch_request_type_list",
                                                                    checked:
                                                                      "true",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .dispatch_request_type_list
                                                                        .general_assist,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .dispatch_request_type_list,
                                                                        "general_assist",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.dispatch_request_type_list.general_assist"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " General Assist "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "general_admit",
                                                                    name:
                                                                      "dispatch_request_type_list",
                                                                    checked:
                                                                      "true",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .dispatch_request_type_list
                                                                        .general_admit,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .dispatch_request_type_list,
                                                                        "general_admit",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.dispatch_request_type_list.general_admit"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " General Admit "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "secure_transport_delivery",
                                                                    name:
                                                                      "dispatch_request_type_list",
                                                                    checked:
                                                                      "true",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .dispatch_request_type_list
                                                                        .secure_transport_delivery,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .dispatch_request_type_list,
                                                                        "secure_transport_delivery",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.dispatch_request_type_list.secure_transport_delivery"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Secure Transport/Delivery "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Requested By",
                                                "label-for": "requested_by"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "requested_by",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "requested_by"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .requested_by,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "requested_by",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.requested_by"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Alarm Zone & Description",
                                                "label-for": "alarm_zone"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "alarm_zone" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "alarm_zone"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .alarm_zone,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "alarm_zone",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.alarm_zone"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Service Dispatched",
                                                "label-for":
                                                  "is_service_dispatched"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "is_service_dispatched"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "mt-1",
                                                              attrs: {
                                                                id:
                                                                  "is_service_dispatched",
                                                                checked: "true",
                                                                name:
                                                                  "check-button",
                                                                switch: "",
                                                                inline: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.reportData
                                                                    .is_service_dispatched,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.reportData,
                                                                    "is_service_dispatched",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "reportData.is_service_dispatched"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " (No / Yes) "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Service Call #",
                                                "label-for":
                                                  "service_call_number"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "service_call_number"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "service_call_number"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .service_call_number,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "service_call_number",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.service_call_number"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Action Taken",
                                                "label-for": "action_taken"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "action_taken",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-textarea",
                                                            {
                                                              attrs: {
                                                                id:
                                                                  "action_taken",
                                                                rows: "3",
                                                                "max-rows": "8"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.reportData
                                                                    .action_taken,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.reportData,
                                                                    "action_taken",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "reportData.action_taken"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Comments / Recommendations",
                                                "label-for": "comments"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "comments",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-textarea",
                                                            {
                                                              attrs: {
                                                                id: "comments",
                                                                rows: "3",
                                                                "max-rows": "8"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.reportData
                                                                    .comments,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.reportData,
                                                                    "comments",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "reportData.comments"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Result",
                                                "label-for": "alarm_status"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "result",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-radio-group",
                                                            {
                                                              staticClass:
                                                                "radio-spacing",
                                                              attrs: {
                                                                options:
                                                                  _vm.resultOptions,
                                                                name:
                                                                  "radios-stacked",
                                                                stacked: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.reportData
                                                                    .alarm_status,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.reportData,
                                                                    "alarm_status",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "reportData.alarm_status"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Subscriber",
                                                "label-for": "is_subscriber"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "is_subscriber"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "mt-1",
                                                              attrs: {
                                                                id:
                                                                  "is_subscriber",
                                                                checked: "true",
                                                                name:
                                                                  "check-button",
                                                                switch: "",
                                                                inline: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.reportData
                                                                    .is_subscriber,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.reportData,
                                                                    "is_subscriber",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "reportData.is_subscriber"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " (Yes / No) "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "4" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Name",
                                                "label-for": "subscriber_name"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "subscriber_name"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "subscriber_name"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .subscriber_name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "subscriber_name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.subscriber_name"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Fire Department",
                                                "label-for": "fire_department"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "fire_department"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "fire_department",
                                                              placeholder:
                                                                "Toronto Pumper #"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .fire_department,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "fire_department",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.fire_department"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Police Service Badge #'S",
                                                "label-for": "police_department"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "police_department"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "police_department",
                                                              placeholder:
                                                                "Service Name, Badge #'s"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .police_department,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "police_department",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.police_department"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Other Names",
                                                "label-for": "other_names"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "other_names" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "other_names"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .other_names,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "other_names",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.other_names"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "6" } })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Report To Client",
                                                "label-for":
                                                  "report_to_client_list"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "report_to_client_list"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "report_to_client_list_mail",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .report_to_client_list
                                                                        .mail,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .report_to_client_list,
                                                                        "mail",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.report_to_client_list.mail"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Mail "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "report_to_client_list_email",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .report_to_client_list
                                                                        .email,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .report_to_client_list,
                                                                        "email",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.report_to_client_list.email"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Email "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "report_to_client_list_left_on_site",
                                                                    checked:
                                                                      "true",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .report_to_client_list
                                                                        .left_on_site,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .report_to_client_list,
                                                                        "left_on_site",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.report_to_client_list.left_on_site"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Left On Site "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.reportID === null
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.$can(
                                                "create",
                                                _vm.abilityRequired
                                              )
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            type: "submit",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          }
                                                        },
                                                        [_vm._v(" Add Report ")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.reportID !== null
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.reportData &&
                                              _vm.clientOptions &&
                                              _vm.$can(
                                                "update",
                                                _vm.abilityRequired
                                              ) &&
                                              _vm.reportData.username ===
                                                _vm.userData.username
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            type: "submit",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Save Changes "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.$can(
                                                "read",
                                                _vm.abilityRequired
                                              )
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant:
                                                              "secondary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.printReport
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Print Report "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm.$can(
                                            "delete",
                                            _vm.abilityRequired
                                          )
                                            ? _c(
                                                "b-row",
                                                { staticClass: "mt-4" },
                                                [
                                                  !_vm.reportData.approved
                                                    ? _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleApprovalToggle
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Approve "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "secondary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleApprovalToggle
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Unapprove "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2408267132
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.reportData &&
      _vm.reportID !== null &&
      (_vm.$can("read", _vm.abilityRequired) ||
        _vm.$can("update", _vm.abilityRequired))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Photos / Images ")])]),
              _c("b-card-body", [
                _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _vm.reportData &&
                        _vm.clientOptions &&
                        _vm.$can("update", _vm.abilityRequired) &&
                        _vm.reportData.username === _vm.userData.username
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      disabled: _vm.preventDoubleClick
                                    },
                                    on: { click: _vm.openCamera }
                                  },
                                  [_vm._v(" Take Picture ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.reportData &&
                        _vm.clientOptions &&
                        _vm.$can("update", _vm.abilityRequired) &&
                        _vm.reportData.username === _vm.userData.username
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      disabled: _vm.preventDoubleClick
                                    },
                                    on: { click: _vm.openImageSelector }
                                  },
                                  [_vm._v(" Add Image ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.reportData.attachment_list
                  ? _c(
                      "section",
                      { staticClass: "grid-view" },
                      _vm._l(_vm.reportData.attachment_list, function(
                        mediaItem
                      ) {
                        return _c(
                          "b-card",
                          {
                            key: mediaItem.filename,
                            ref: mediaItem.filename,
                            refInFor: true,
                            attrs: { id: mediaItem.filename, "no-body": "" }
                          },
                          [
                            mediaItem.filename.slice(-3) === "jpg"
                              ? _c(
                                  "div",
                                  { staticClass: "item-img text-center" },
                                  [
                                    _c("thumbs-lazy-image-component", {
                                      staticClass: "card-img-top",
                                      attrs: {
                                        src: mediaItem.access_url,
                                        "src-placeholder":
                                          "@/assets/images/noImage.png"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            mediaItem.filename.slice(-3) === "jpg" &&
                            _vm.$can("update", _vm.abilityRequired) &&
                            _vm.reportData.username === _vm.userData.username
                              ? _c(
                                  "b-card-footer",
                                  { staticClass: "pt-1 pb-1 border-dark" },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                attrs: {
                                                  variant: "primary",
                                                  block: "",
                                                  disabled:
                                                    _vm.preventDoubleClick
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteImage(
                                                      mediaItem
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Delete Image ")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _c("input", {
                  ref: "cameraInput",
                  staticStyle: { display: "none" },
                  attrs: {
                    type: "file",
                    accept: "image/*",
                    capture: "environment"
                  },
                  on: { change: _vm.handleImageChange }
                }),
                _c("input", {
                  ref: "imageInput",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", accept: "image/*" },
                  on: { change: _vm.handleImageChange }
                })
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.reportData &&
      _vm.reportID !== null &&
      (_vm.$can("read", _vm.abilityRequired) ||
        _vm.$can("update", _vm.abilityRequired))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Files ")])]),
              _c("b-card-body", [
                _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _vm.reportData &&
                        _vm.clientOptions &&
                        _vm.$can("update", _vm.abilityRequired) &&
                        _vm.reportData.username === _vm.userData.username
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      disabled: _vm.preventDoubleClick
                                    },
                                    on: { click: _vm.openFileSelector }
                                  },
                                  [_vm._v(" Add File ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.reportData.attachment_list
                  ? _c(
                      "section",
                      { staticClass: "grid-view" },
                      _vm._l(_vm.reportData.attachment_list, function(
                        mediaItem
                      ) {
                        return _c(
                          "b-card",
                          {
                            key: mediaItem.filename,
                            ref: mediaItem.filename,
                            refInFor: true,
                            attrs: { id: mediaItem.filename, "no-body": "" }
                          },
                          [
                            mediaItem.filename.slice(-3) !== "jpg"
                              ? _c(
                                  "b-card-footer",
                                  { staticClass: "pt-1 pb-1 border-dark" },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                attrs: {
                                                  variant: "primary",
                                                  block: "",
                                                  disabled:
                                                    _vm.preventDoubleClick
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.downloadFile(
                                                      mediaItem
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Download File ")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm.reportData &&
                                        _vm.clientOptions &&
                                        _vm.$can(
                                          "update",
                                          _vm.abilityRequired
                                        ) &&
                                        _vm.reportData.username ===
                                          _vm.userData.username
                                          ? _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                    attrs: {
                                                      variant: "primary",
                                                      block: "",
                                                      disabled:
                                                        _vm.preventDoubleClick
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteFile(
                                                          mediaItem
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(" Delete File ")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _c("input", {
                  ref: "fileInput",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", accept: "*/*" },
                  on: { change: _vm.handleFileChange }
                })
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }